import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

export default {
  isHost(state) {
    let id = state.roomInfo.identity;
    return isHostIdentity(id);
  },

  profileInfo(state) {
    const omitKeys = ['token', 'room_token', 'room', 'id', 'error'];
    const roomName = state.roomInfo?.room_name;
    return omit({ ...state.roomInfo, roomName }, omitKeys);
  },

  getParticipantRecords(state) {
    return (participantId) => {
      let participantRecord = (state.questionnaireAnswers || []).find(
        (i) => i.identity === participantId
      );

      if (!participantRecord && participantId === state.roomInfo?.identity) {
        participantRecord = state.guestAnswers;
      }

      return participantRecord;
    };
  },

  getParticipantJoinInfo(state) {
    return (participantId) => {
      return state.roomChat.findLast((message) => {
        const { system, identity, txt } = message;
        return identity === participantId && system && txt.includes('joined');
      });
    };
  },

  interviewFinished: (state) => {
    if (isEmpty(state.roomInfo)) return false;

    const { identity, finished } = state.roomInfo;

    if (finished) return true;

    if (state.finishedInterviewInfo && identity) {
      return identity === state.finishedInterviewInfo.identity;
    }

    return false;
  },
};

const isHostIdentity = (identity = '') => {
  return identity.indexOf('host_') === 0;
};
