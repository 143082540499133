import api from '@/factories/api';
import router from '@/router';

export default {
  namespaced: true,

  state: {
    loading: false,
    loginInfo: null,
    loginInfoLoaded: false,
    signUpInfo: null,
    CSRFToken: null,
  },

  mutations: {
    setLoading: (state, loading) => {
      state.loading = loading;
    },
    setLoginInfoLoaded: (state, loading) => {
      state.loginInfoLoaded = loading;
    },
    setLoginInfo: (state, info) => {
      state.loginInfo = info;
    },
    setCSRFToken: (state, token) => {
      state.CSRFToken = token;
    },
    setSignUpInfo: (state, info) => {
      state.signUpInfo = info;
    },
  },

  actions: {
    async logout() {
      await api().post('users/logout');
    },

    async login({ commit, dispatch }, data) {
      commit('setLoading', true);
      try {
        return await dispatch('processAuthRequest', {
          data,
          method: 'post',
          path: 'users/login',
        });
      } catch (err) {
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },

    async signup({ commit, dispatch }, data) {
      try {
        return await dispatch('processAuthRequest', {
          data,
          method: 'post',
          path: 'users/signup',
        });
      } catch (err) {
        throw err;
      }
    },

    async resetPassword({ dispatch }, data) {
      try {
        return await dispatch('processAuthRequest', {
          data,
          method: 'post',
          path: 'users/reset_password',
        });
      } catch (err) {
        throw err;
      }
    },

    async prepareResetPassword({ dispatch }, params) {
      try {
        return await dispatch('processAuthRequest', {
          data: { params },
          method: 'get',
          path: 'users/reset_password_confirm',
        });
      } catch (err) {
        throw err;
      }
    },

    async setNewPassword({ commit, dispatch }, data) {
      commit('setLoading', true);
      try {
        return await dispatch('processAuthRequest', {
          data,
          method: 'post',
          path: 'users/reset_password_confirm',
        });
      } catch (err) {
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },

    async getLoginInfo({ commit, state, dispatch }, code) {
      if (state.loginInfo || state.loginInfoLoaded) return;

      commit('setLoading', true);
      try {
        let path = 'users/login';
        if (code) {
          path += `?code=${code}`;
        }

        const res = await dispatch('processAuthRequest', {
          method: 'get',
          path,
        });

        if (res?.action === 'validate_okta') {
          router.push('/');
        }

        if (Array.isArray(res) && res.length === 0) {
          router.push('/');
        }

        commit('setLoginInfo', res);
      } finally {
        commit('setLoading', false);
        commit('setLoginInfoLoaded', true);
      }
    },

    async getSignUpInfo({ commit, state, dispatch }, plan = '') {
      if (state.signUpInfo) return;

      commit('setLoading', true);

      try {
        const res = await dispatch('processAuthRequest', {
          method: 'get',
          path: `users/signup?plan=${plan}`,
        });
        commit('setSignUpInfo', res);
      } finally {
        commit('setLoading', false);
      }
    },

    // extract or add CSRF-token
    async processAuthRequest({ commit, state }, params) {
      const { data, method, path = '' } = params;
      let config = null;

      if (method === 'post' || path.includes('reset_password_confirm')) {
        config = {
          returnMessage: true,
          CSRFToken: state.CSRFToken || null,
        };
      }

      const res = await api(config)[method](path, data);
      const csrf_token = res?.csrf_token || res?.data?.csrf_token;
      if (csrf_token) {
        commit('setCSRFToken', csrf_token);
      }

      return res;
    },
  },
};
