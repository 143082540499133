const addRoomsRoutes = (router) => {
  router.addRoute({
    path: '/rooms',
    component: () =>
      import(
        /* webpackChunkName: "room" */ '@/components/rooms/components/RoomsLayout'
      ),
    children: [
      {
        path: '',
        name: 'room-home',
        props: true,
        component: () =>
          import(/* webpackChunkName: "room" */ '@/components/rooms/RoomHome'),
      },
      {
        path: 'invite',
        name: 'room-invite',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "room" */ '@/components/rooms/RoomInvite'
          ),
      },
      {
        path: 'board',
        name: 'room-board',
        component: () =>
          import(
            /* webpackChunkName: "room" */ '@/components/rooms/RoomAssignmentBoard.vue'
          ),
      },
      {
        path: '*',
        redirect: { name: 'room-home' },
      },
    ],
  });

  checkApexGuard(router);
};

const checkApexGuard = (router) => {
  if (window.location.hostname !== 'apex.vmcteam.com') return;

  router.beforeEach((to, from, next) => {
    if (!to.fullPath.includes('/rooms')) {
      next('/rooms');
    } else {
      next();
    }
  });

  shutdownIntercom();
};

const shutdownIntercom = () => {
  if (!window.Intercom) return;
  Intercom('shutdown');
};

export default addRoomsRoutes;
