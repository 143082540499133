import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('auth');

const authRoutes = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "room" */ '@/components/layouts/AuthLayout'),
    children: [
      {
        path: '/get-started',
        name: 'app-signup',
        component: loadView('SignUpPage'),
      },
      {
        path: '/signup',
        redirect: { name: 'app-signup' },
      },
      {
        path: '/login',
        name: 'app-login',
        component: loadView('LoginPage'),
      },
      {
        path: '/student-login',
        redirect: { name: 'portal-home' },
      },
      {
        path: '/account/reset_password',
        name: 'app-password-reset',
        component: loadView('ResetPasswordPage'),
      },
      {
        path: '/account/reset_password_confirm/:email?/:code?',
        name: 'app-password-reset-confirm',
        props: true,
        component: loadView('ResetPasswordConfirmPage'),
      },
    ],
  },
];

export default authRoutes;
